export const COLOR_PROGRAMS = {
  'body-goals': {
    50: '#008C8133',
    100: '#C7F9E3',
    400: '#008C81',
  },
  'clinicare': {
    50: '#A1E0FF66',
    100: '#A1E0FF',
    400: '#5188B7',
  },
  'body-for-baby': {
    50: '#F4CBFF80',
    100: '#F4CBFF',
    400: '#7E5098',
  },
}