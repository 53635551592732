export const LIST_TESTIMONI = [
  {
    id: '1',
    name: 'Lulu',
    age: '32',
    location: 'Jakarta',
    photo: 'https://placehold.co/1280',
    program: 'Body Goals',
    programId: 'body-goals',
    description: 'Awalnya diet asal-asalan, dari pakai pil pelangsing yang langsung turun drastis tapi naik laginya pun drastis. Sampai minum obat pencahar yang bikin jadi sembelit terus. Setelah ikut program dietela, tidak ada lagi pil pelangsing dan obat pencahar, pola makan jadi baik, masih bisa makan enak dan bonus BB/LP turun tiap minggu.',
  }, {
    id: '2',
    name: 'Alodia Isnasari Putri',
    age: '35',
    location: 'Pamulang',
    photo: 'https://placehold.co/1280',
    program: 'Body Goals',
    programId: 'body-goals',
    description: 'Sejak setahun kena GERD, setiap bulan harus ke IGD karena kambuh, padahal udah minum obat yang rajin. Mau makan serba salah dan bingung apa yang aman untukku, dan BB juga naik drastis. Akhirnya aku coba Dietela, berat badan turun 5 Kg dan GERD jadi jarang kambuh selain itu menunya juga bisa disesuaikan dengan menu keluarga.',
  }, {
    id: '3',
    name: 'Rifqi Mardhiar',
    age: '25',
    location: 'Pamulang',
    photo: 'https://placehold.co/1280',
    program: 'Body Goals',
    programId: 'body-goals',
    description: 'Sudah 6 tahun terakhir bergelut dengan asam urat di usia muda, saya memutuskan ikut dietela untuk program Clinicare. Hasilnya asam urat turun dan BB pun juga ikut turun.',
  }, {
    id: '4',
    name: 'Putri Habibie',
    age: '31',
    location: 'Jakarta',
    photo: 'https://placehold.co/1280',
    program: 'Body Goals',
    programId: 'body-goals',
    description: 'Mengikuti program Dietela,so far the best lifestyle throughout my life! Hidup bahagia tanpa pantangan makan, menikmati proses sambil makan enak dan olahraga sesuai kemampuan dan tanpa paksaan. Gak ada stress harus turun BB cepet-cepet. Mentalku tenang dan sehat.',
  }, {
    id: '5',
    name: 'Rifqi Mardhiar',
    age: '25',
    location: 'Pamulang',
    photo: 'https://placehold.co/1280',
    program: 'Clinicare',
    programId: 'clinicare',
    description: 'Sudah 6 tahun terakhir bergelut dengan asam urat di usia muda, saya memutuskan ikut dietela untuk program Clinicare. Hasilnya asam urat turun dan BB pun juga ikut turun.',
  },
]