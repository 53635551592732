<template>
  <c-box
    :background-color="['#F2F2F2', '#FFF']"
    width="100%"
  >
    <c-box
      position="relative"
      mx="auto"
      :mt="['0', (isAuthenticated ? '0' : '16px')]"
      :mb="['0', '16px']"
      :background-color="['#F2F2F2', '#FFF']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :min-height="['calc(100vh - 62px)', '74vh']"
      width="100%"
      max-width="1270px"
    >
      <Portal
        v-if="isAuthenticated"
        to="breadcrumb"
      >
        <c-box
          width="100%"
          max-width="1270px"
          mx="auto"
        >
          <BreadcrumbPath
            px="90px"
            py="20px"
            :paths="[
              { label: 'Dashboard', href: '/' },
              { label: 'Program', href: '/select-program' },
              { label: programDetail?.name, isCurrent: true },
            ]"
          />
        </c-box>
      </Portal>

      <c-box :padding="['0 0 0 0', '16px 16px 0 16px']">
        <c-box
          width="100%"
          background-color="program.50"
          :border-radius="['0px', '12px']"
          :box-shadow="['none', '4px 4px 4px rgba(0, 0, 0, 0.15)']"
        >
          <c-box
            width="100%"
            display="grid"
            :grid-gap="['16px', '40px']"
            :grid-template-columns="['100px 1fr', '275px 1fr']"
            :padding="['16px', '34px']"
          >
            <c-box
              width="100%"
              display="flex"
              justify-content="center"
              align-items="center"
            >
              <c-image
                v-if="programDetail.photoUrl"
                width="100%"
                :max-width="['100px', '275px']"
                height="100%"
                :max-height="['128px', '350px']"
                :src="programDetail.photoUrl"
                border-radius="8px"
                object-fit="cover"
                object-position="50% 20%"
              />
            </c-box>
            <c-box
              display="flex"
              flex-direction="column"
              justify-content="center"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="36px"
                :color="['neutral.black', 'neutral.superDarkGray']"
              >
                {{ programDetail.name }}
              </BaseText>
              <BaseDivider />
              <!-- <BaseText
                size-mobile="12px"
                size-desktop="18px"
                color="#008C81"
                text-align="justify"
              >
                {{ programDetail.description }}
              </BaseText> -->
              <c-box
                :color="['neutral.black', 'neutral.superDarkGray']"
                width="100%"
                :font-size="['12px', '18px']"
                :font-weight="['400', '500']"
                v-html="programDetail.description"
              />
            </c-box>

            <!-- Diet Sehat ala Dietela -->
            <!-- <c-box
              grid-column="1 / -1"
              display="flex"
              flex-direction="column"
              justify-content="center"
              :align-items="['start', 'center']"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="28px"
                color="#008C81"
              >
                Diet Sehat ala Dietela
              </BaseText>
              <BaseDivider />
              <c-box
                margin-top="8px"
                display="grid"
                :grid-template-columns="['repeat(2, 1fr)', 'repeat(4, 1fr)']"
                grid-gap="16px"
              >
                <c-box
                  v-for="item in LIST_PROGRAMS[0].keyPoints"
                  :key="item.id"
                  background-color="white"
                  border-radius="16px"
                  padding="16px"
                  display="flex"
                  flex-direction="column"
                  gap="16px"
                  align-items="center"
                  :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
                >
                  <BaseText
                    size-mobile="18px"
                    size-desktop="20px"
                    :color="['#008C81', 'unset']"
                    text-align="center"
                  >
                    {{ item.name }}
                  </BaseText>
                  <c-image
                    :src="item.image"
                    min-height="80px"
                    height="100%"
                    :max-height="['80px', '100px']"
                  />
                  <BaseText
                    size-mobile="10px"
                    size-desktop="14px-2"
                    margin-bottom="auto"
                    text-align="center"
                  >
                    {{ item.description }}
                  </BaseText>
                </c-box>
              </c-box>
            </c-box> -->
          </c-box>
          <c-box
            id="table-comparation-target"
            background="linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 100%)"
            border-top-left-radius="50px"
            border-top-right-radius="50px"
            :padding-bottom="['0px', '16px']"
          >
            <TableComparationTarget 
              :slug="$route.params.id"
            />
            <!-- <TargetProgramAlt 
              :program="program"
            /> -->
          </c-box>
        </c-box>
      </c-box>
      <c-box
        padding="0 16px"
        color="primary.500"
        :font-weight="['500', '700']"
        :font-size="['16px', '20px']"
        :margin-top="['16px','32px']"
      >
        <c-text text-align="center">
          Masih bingung terkait program {{ programDetail.name }}? Yuk, simak video berikut ini!
        </c-text>
      </c-box>

      <c-box
        padding="0 16px"
        margin-top="16px"
      >
        <c-box
          max-width="854px"
          margin="auto"
        >
          <BasePlayer
            v-if="programDetail?.videoUrl"
            :key="programDetail?.videoUrl"
          >
            <c-box
              class="plyr__video-embed"
              border-radius="16px"
            >
              <c-box
                as="iframe"
                :src="programDetail?.videoUrl"
                allowtransparency
              />
            </c-box>
          </BasePlayer>
        </c-box>
      </c-box>

      <c-box
        v-if="!!productId"
        :padding="['0 16px', '16px']"
        margin-top="16px"
      >
        <TableProductComparation
          :product-id="productId"
        >
          <template #button="{ productService }">
            <c-flex :gap="['8px', '16px']">
              <c-flex
                v-if="isAuthenticated"
                justify-content="center"
                align-items="center"
                :height="['34px', '50px']"
                :width="['34px', '50px']"
                :min-height="['34px', '50px']"
                :min-width="['34px', '50px']"
                border="1px solid #008C81"
                border-radius="12px"
                :cursor="isLoading.addToCart ? 'not-allowed' : 'pointer'"
                :_hover="{
                  backgroundColor: '#e9fffb'
                }"
                @click="onClickAddToCart(productService || {})"
              >
                <c-circular-progress
                  v-if="isLoading.addToCart"
                  is-indeterminate
                  color="primary"
                  size="100%"
                />
                <inline-svg
                  v-else
                  :src="require('@/assets/icons/icon-cart.svg')"
                  width="100%"
                  min-width="100%"
                  fill="#008C81"
                />
              </c-flex>
              <BaseButton
                v-chakra="{
                  color: 'primary.400',
                  fontWeight: ['400', '500'],
                  fontSize: ['12px', '18px']
                }"
                size="small"
                variant="contained"
                w="100%"
                border-radius="1000px"
                color="secondary"
                :is-loading="isLoading.buyNow"
                @click="onClickBuyNow({
                  productId: productService?.productId || '',
                  productServiceId: productService?.id || ''
                })"
              >
                Beli Sekarang
              </BaseButton>
            </c-flex>
          </template>
        </TableProductComparation>
      </c-box>

      <c-flex
        :margin-top="['16px', '0']"
        justify-content="center"
        :padding="['0 16px', '0']"
      >
        <c-flex
          position="relative"
          gap="12px"
          :padding="['12px 40px', '12px']"
          justify-content="center"
          align-items="center"
          flex-direction="column"
          width="100%"
          max-width="850px"
          background-color="#FDF9BC"
          border-top-left-radius="100px"
          border-bottom-right-radius="100px"
          overflow="hidden"
        >
          <c-box
            :display="['block','none']"
            position="absolute"
            width="100px"
            height="100px"
            background-color="#FAF59A"
            border-radius="50%"
            top="-40px"
            left="0"
          />
          <c-box
            :display="['block','none']"
            position="absolute"
            width="100px"
            height="100px"
            background-color="#FAF59A"
            border-radius="50%"
            bottom="-40px"
            right="0"
          />
          <BaseText
            position="relative"
            size-mobile="12px-2"
            size-desktop="20px"
            color="#005A64"
            text-align="center"
          >
            Masih bingung pilih simpel atau intensif program?
          </BaseText>
          <BaseButton
            position="relative"
            size="small"
            border-radius="1000px"
            @click="$scrollTo('#table-comparation-target', 500, {
              offset: -80,
            })"
          >
            Bandingkan Program
          </BaseButton>
        </c-flex>
      </c-flex>

      <c-box
        padding-top="16px"
        padding-bottom="16px"
      >
        <c-box
          position="relative"
          width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="28px"
            margin-bottom="8px"
            padding-inline="16px"
          >
            Apa Kata Mereka?
          </BaseText>

          <c-box
            :padding-inline="['0px', '16px']"
          >
            <VueSlickCarousel
              v-if="listTestimoni.length > 0"
              v-chakra="{
                '.slick-slide': {
                  padding: '0 16px 8px 0',
                  '@media(max-width: 767px)': {
                    padding: '0 16px',
                  },
                },
                '.slick-dots': {
                  bottom: '-25px',
                  li: {
                    '@media(max-width: 767px)': {
                      margin: '0 3px',
                    },
                    button: {
                      '::before': {
                        fontSize: '8px',
                        color: '#D9D9D9',
                        opacity: '1',
                        '@media(max-width: 767px)': {
                          fontSize: '10px',
                        },
                      },
                    },
                    '&.slick-active': {
                      button: {
                        '::before': {
                          fontSize: '12px',
                          color: '#008C81',
                          opacity: '1',
                        },
                      },
                    },
                  },
                },
              }"
              v-bind="settings"
            >
              <c-box
                v-for="(item) in listTestimoni"
                :key="item.id"
              >
                <c-box
                  position="relative"
                  :width="['100%', '332px']"
                  :h="['auto', '305px']"
                  background-color="#008C81"
                  border-radius="12px"
                  padding="16px"
                  display="flex"
                  flex-direction="column"
                  gap="8px"
                  padding-bottom="52px"
                  margin-bottom="39px"
                >
                  <c-box
                    display="flex"
                    gap="16px"
                  >
                    <c-box
                      min-width="60px"
                      width="60px"
                      height="60px"
                    >
                      <c-image
                        v-if="item.image"
                        :src="item.image"
                        height="100%"
                        width="100%"
                        border-radius="100%"
                        object-fit="cover"
                        object-position="50% 20%"
                      />
                    </c-box>
                    <c-box
                      display="flex"
                      flex-direction="column"
                      justify-content="center"
                      width="100%"
                    >
                      <BaseText
                        size-mobile="14px-2"
                        size-desktop="16px"
                        color="white"
                      >
                        {{ item.name }} ({{ item.age }} Tahun)
                      </BaseText>
                      <BaseText
                        size-mobile="12px"
                        size-desktop="14px-2"
                        color="white"
                      >
                        {{ item.location }}
                      </BaseText>
                    </c-box>
                  </c-box>
                  <c-box
                    height="100%"
                  >
                    <BaseText
                      size-mobile="12px"
                      size-desktop="14px-2"
                      margin-bottom="auto"
                      text-align="justify"
                      color="white"
                    >
                      {{ item.description }}
                    </BaseText>
                  </c-box>
                  <c-box
                    :width="['190px','222px']"
                    :min-height="['64px', '67px']"
                    background-color="white"
                    position="absolute"
                    bottom="0"
                    left="50%"
                    border="1px solid #008C81"
                    border-radius="12px"
                    transform="translate(-50%, 50%)"
                    padding="5px"
                    display="flex"
                    flex-direction="column"
                    justify-content="center"
                    align-items="center"
                    gap="5px"
                  >
                    <BaseText
                      size-mobile="10px"
                      size-desktop="12px"
                    >
                      Program yang digunakan: {{ item.product.name }}
                    </BaseText>
                    <BaseButton
                      border-radius="1000px"
                      :right-svg-icon="require('@/assets/icons/icon-arrow-right.svg')"
                      right-svg-icon-color="white"
                      height="34px"
                      @click="handleClickTestimoniItem(item)"
                    >
                      Lihat layanan
                    </BaseButton>
                  </c-box>
                </c-box>
              </c-box>
            </VueSlickCarousel>
          </c-box>
        </c-box>
      </c-box>

      <c-box :padding="['16px 16px 0 16px']">
        <c-box
          position="relative"
          background-color="#F1E456"
          width="100%"
          height="100%"
          :border-radius="['4px 4px 4px 40px', '4px 4px 40px 100px']"
          overflow="hidden"
          display="flex"
        >
          <c-box
            position="absolute"
            :display="['none', 'block']"
            background-color="#F4CC46"
            width="500px"
            height="500px"
            border-radius="100%"
            z-index="0"
            bottom="15%"
            left="5%"
          />

          <c-box
            position="relative"
            :min-height="['113px', '300px']"
            width="100%"
            height="100%"
            display="flex"
            justify-content="space-between"
            align-items="center"
            :padding="['12px 10%', 'auto 15%']"
          >
            <c-box
              width="100%"
              height="100%"
            >
              <c-image
                src="https://ik.imagekit.io/dietela/pwa_webp/program_page/forenterprise.webp?updatedAt=1685497643580"
                height="100%"
                :max-height="['88.47px', '184.31px']"
              />
            </c-box>

            <c-box
              display="flex"
              flex-direction="column"
              align-items="flex-end"
              :gap="['5px', '16px']"
              width="100%"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="28px"
                color="#008C81"
              >
                For Enterprise
              </BaseText>
              <BaseText
                size-mobile="12px"
                size-desktop="16px"
                color="#008C81"
                text-align="end"
              >
                Program Khusus Perusahaanmu
              </BaseText>
              <BaseButton
                size="medium"
                border-radius="1000px"
                :width="['unset', '290px']"
                :right-svg-icon="require('@/assets/icons/icon-arrow-right.svg')"
                right-svg-icon-color="white"
                @click="handleMoreForEnterprise"
              >
                Lebih Lanjut
              </BaseButton>
            </c-box>
          </c-box>
        </c-box>
      </c-box>

      <c-box
        :padding="['16px 0 0 0', '16px 16px 0 16px']"
      >
        <c-box
          width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
          align-items="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="28px"
          >
            Masalah Lainnya
          </BaseText>

          <c-box
            v-dragscroll.x="!$isMobile()"
            width="100%"
            overflow-y="hidden"
            overflow-x="scroll"
            display="flex"
            padding-top="8px"
            padding-bottom="16px"
            class="no-scrollbar"
            @click.capture="e => dragScrollClickFix.onClickCapture(e)"
            @dragscrollstart="dragScrollClickFix.onDragScrollStart()"
            @dragscrollend="dragScrollClickFix.onDragScrollEnd()"
          >
            <c-box
              display="grid"
              :grid-gap="['20px', '16px']"
              :grid-template-columns="['repeat(5, 180px)', 'repeat(5, 230px)']"
              margin="auto"
            >
              <c-box
                v-for="(item) in programDetail.otherProblems"
                :key="item.id"
                v-chakra="{
                  '&:first-child': {
                    marginLeft: '16px'
                  },
                  '&:last-child': {
                    marginRight: '16px'
                  },
                  ':hover': {
                    backgroundColor: '#f3f3f3'
                  },
                  ':active': {
                    backgroundColor: '#eaeaea'
                  }
                }"
                as="button"
                background-color="white"
                :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
                border-radius="16px"
                padding="16px"
                display="flex"
                flex-direction="column"
                gap="8px"
                justify-content="center"
                align-items="center"
                @click="handleClickOtherProblem(item)"
              >
                <BaseText
                  size-mobile="18px"
                  size-desktop="28px"
                  :color="['#008C81']"
                  text-align="center"
                >
                  {{ item.title }}
                </BaseText>
                <c-image
                  :src="item.image"
                  min-height="80px"
                  height="100%"
                  :max-height="['80px', '100px']"
                />
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="14px-2"
                  margin-bottom="auto"
                  text-align="center"
                >
                  {{ item.description }}
                </BaseText>
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
// import TargetProgramAlt from '@/views/client/program/_widgets/target-program-alt.vue'
import TableComparationTarget from '@/views/client/program/_widgets/table-comparation-target.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { LIST_PROGRAMS } from '@/views/client/_constant/list-program'
import { LIST_TESTIMONI } from '@/views/client/_constant/list-testimoni'
import { COLOR_PROGRAMS } from '@/constants/colors'
import BaseText from '@/components/elements/base-text.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseButton from '@/components/elements/base-button.vue'
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import TableProductComparation from '@/components/table-product-comparation.vue'
import BasePlayer from '@/components/elements/base-player.vue'

class DragScrollClickFix {
  constructor() {
    this.DRAG_DELAY = 100 // This is the minimal delay to consider a click to be a drag, mostly usefull for touch devices
    this.timer = null
    this.dragging = false
  }

  onDragScrollStart() {
    this.timer = setTimeout(() => this.onTimer(), this.DRAG_DELAY)
  }

  onTimer() {
    this.timer = null
    this.dragging = true
  }

  onDragScrollEnd() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    setTimeout(() => this.dragging = false)
  }

  onClickCapture(e) {
    if (this.dragging) {
      this.dragging = false
      e.preventDefault()
      e.stopPropagation()
    }
  }
}

export default {
  name: 'ProgramDetail',
  components: {
    BasePlayer,
    TableProductComparation,
    // TargetProgramAlt,
    TableComparationTarget,
    BaseButton,
    BaseDivider,
    BaseText,
    BreadcrumbPath,
    VueSlickCarousel,
  },
  directives: {
    dragscroll,
  },
  data() {
    return {
      isLoading: {
        addToCart: false,
        buyNow: false,
      },
      productId: null,
      LIST_PROGRAMS,
      LIST_TESTIMONI,
      COLOR_PROGRAMS,
      listTestimoni: [],
      programDetail: {},
      dragged: false,
      dragTimeout: null,
      dragScrollClickFix: new DragScrollClickFix(),
      settings: {
        dots: false,
        arrows: false,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        slidesToScroll: 1,
        pauseOnHover: true,
        pauseOnFocus: true,
        pauseOnDotsHover: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              adaptiveHeight: true,
              variableWidth: false,
              slidesToShow: 1,
            },
          },
        ],
      }, 
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      program: 'clients/product',
      cartItems: 'cart/items',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        const colors = this.COLOR_PROGRAMS[val.params.id]
        if (colors) {
          document.documentElement.style.setProperty('--program-50', colors['50'])
          document.documentElement.style.setProperty('--program-100', colors['100'])
          document.documentElement.style.setProperty('--program-400', colors['400'])
        }

        this.initGetProgramPage()
        this.getProgram(val.params.id)
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push('/')
          })
      },
    },
  },
  mounted() {
    this.init()
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    ...mapActions({
      onUpdateCart: 'cart/onUpdateCart',
      list: 'cart/list',
      getProgramPageById: 'general/getProgramPageById',
      getTestimoni: 'general/getTestimoni',
      getProgram: 'clients/getProductById',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    handleMoreForEnterprise() {
      this.$router.push({
        name: 'client.enterpriseService',
      })
    },
    handleScrollTopIfSamePath(productId, programSlug) {
      if (productId === this.$route.params.id || programSlug === this.$route.params.id) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        return true
      }
      return false
    },
    handleClickTestimoniItem(item) {
      const isSamePath = this.handleScrollTopIfSamePath(item.product.id, item.product.slug)
      if (isSamePath) return

      this.$router.push({
        name: 'client.program.detail',
        params: { id: item.product?.slug ? item.product?.slug : item.product?.id },
      })
    },
    handleClickOtherProblem(item) {
      const isSamePath = this.handleScrollTopIfSamePath(item.productId)
      if (isSamePath) return

      this.$router.push({
        name: 'client.program.detail',
        params: { id: item?.product?.slug ? item?.product?.slug : item?.productId },
      })
    },
    async initGetProgramPage() {
      const res = await this.getProgramPageById(this.$route.params.id)
      this.programDetail = res.data
      this.productId = res.data?.id
    },
    async init() {
      const res = await this.getTestimoni()
      this.listTestimoni = res.data
    },
    async onClickAddToCart(item) {
      if (this.isLoading.addToCart) {
        return
      }
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'auth', query: { tab: 'login' } })
        return
      }
      this.isLoading.addToCart = true

      try {
        let params = {
          productServiceId: item.id,
          extend: 1,
        }
        window.fbq('track', 'AddToCart')
        await this.onUpdateCart(params)
          .then(() => {
            const findIndex = this.cartItems.findIndex((v) => v.productService?.id === item.id)
            if (findIndex === -1) {
              this.cartItems.push({
                quantity: 1,
                productService: {
                  ...item,
                  product: {
                    photoUrl: this.photoUrl,
                  },
                },
              })
              return
            }
            const cartItem = this.cartItems[findIndex]
            cartItem.quantity++
          })
        await this.list()
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading.addToCart = false
      }
    },
    async onClickBuyNow({
      productId,
      productServiceId,
    }) {
      this.isLoading.buyNow = true
      if (!this.isAuthenticated) {
        this.$router.push({
          name: 'client.buy-program.step-1',
          query: {
            product_id: productId,
            product_service_id: productServiceId,
            duration: 1,
          },
        })
        return
      }
      window.fbq('track', 'BuyNow')

      const selectedCart = {
        'quantity': 1,
        'productService': {
          'id': productServiceId,
        },
        '_isBuyNow': true,
      }
      this.setSelectedCart(selectedCart)
      this.$router.push({ name: 'client.cart.check-order' })
      this.isLoading.buyNow = false
    },
  },
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

::v-deep .program-description-list {
  gap: 10px !important;
}

::v-deep .program-description-list ul {
  margin-left: 18px !important;
}
@media (min-width: 768px) {
  ::v-deep .program-description-list {
    gap: 120px !important;
  }

  ::v-deep .program-description-list ul {
    margin-left: 32px !important;
  }
}
</style>
