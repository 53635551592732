var render = function () {
  var _vm$programDetail, _vm$programDetail2, _vm$programDetail3, _vm$programDetail4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mt": ['0', _vm.isAuthenticated ? '0' : '16px'],
      "mb": ['0', '16px'],
      "background-color": ['#F2F2F2', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "min-height": ['calc(100vh - 62px)', '74vh'],
      "width": "100%",
      "max-width": "1270px"
    }
  }, [_vm.isAuthenticated ? _c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "mx": "auto"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Program',
        href: '/select-program'
      }, {
        label: (_vm$programDetail = _vm.programDetail) === null || _vm$programDetail === void 0 ? void 0 : _vm$programDetail.name,
        isCurrent: true
      }]
    }
  })], 1)], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "padding": ['0 0 0 0', '16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "program.50",
      "border-radius": ['0px', '12px'],
      "box-shadow": ['none', '4px 4px 4px rgba(0, 0, 0, 0.15)']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "grid",
      "grid-gap": ['16px', '40px'],
      "grid-template-columns": ['100px 1fr', '275px 1fr'],
      "padding": ['16px', '34px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm.programDetail.photoUrl ? _c('c-image', {
    attrs: {
      "width": "100%",
      "max-width": ['100px', '275px'],
      "height": "100%",
      "max-height": ['128px', '350px'],
      "src": _vm.programDetail.photoUrl,
      "border-radius": "8px",
      "object-fit": "cover",
      "object-position": "50% 20%"
    }
  }) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "36px",
      "color": ['neutral.black', 'neutral.superDarkGray']
    }
  }, [_vm._v(" " + _vm._s(_vm.programDetail.name) + " ")]), _c('BaseDivider'), _c('c-box', {
    attrs: {
      "color": ['neutral.black', 'neutral.superDarkGray'],
      "width": "100%",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500']
    },
    domProps: {
      "innerHTML": _vm._s(_vm.programDetail.description)
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "id": "table-comparation-target",
      "background": "linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 100%)",
      "border-top-left-radius": "50px",
      "border-top-right-radius": "50px",
      "padding-bottom": ['0px', '16px']
    }
  }, [_c('TableComparationTarget', {
    attrs: {
      "slug": _vm.$route.params.id
    }
  })], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": "0 16px",
      "color": "primary.500",
      "font-weight": ['500', '700'],
      "font-size": ['16px', '20px'],
      "margin-top": ['16px', '32px']
    }
  }, [_c('c-text', {
    attrs: {
      "text-align": "center"
    }
  }, [_vm._v(" Masih bingung terkait program " + _vm._s(_vm.programDetail.name) + "? Yuk, simak video berikut ini! ")])], 1), _c('c-box', {
    attrs: {
      "padding": "0 16px",
      "margin-top": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "max-width": "854px",
      "margin": "auto"
    }
  }, [(_vm$programDetail2 = _vm.programDetail) !== null && _vm$programDetail2 !== void 0 && _vm$programDetail2.videoUrl ? _c('BasePlayer', {
    key: (_vm$programDetail3 = _vm.programDetail) === null || _vm$programDetail3 === void 0 ? void 0 : _vm$programDetail3.videoUrl
  }, [_c('c-box', {
    staticClass: "plyr__video-embed",
    attrs: {
      "border-radius": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "iframe",
      "src": (_vm$programDetail4 = _vm.programDetail) === null || _vm$programDetail4 === void 0 ? void 0 : _vm$programDetail4.videoUrl,
      "allowtransparency": ""
    }
  })], 1)], 1) : _vm._e()], 1)], 1), !!_vm.productId ? _c('c-box', {
    attrs: {
      "padding": ['0 16px', '16px'],
      "margin-top": "16px"
    }
  }, [_c('TableProductComparation', {
    attrs: {
      "product-id": _vm.productId
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var productService = _ref.productService;
        return [_c('c-flex', {
          attrs: {
            "gap": ['8px', '16px']
          }
        }, [_vm.isAuthenticated ? _c('c-flex', {
          attrs: {
            "justify-content": "center",
            "align-items": "center",
            "height": ['34px', '50px'],
            "width": ['34px', '50px'],
            "min-height": ['34px', '50px'],
            "min-width": ['34px', '50px'],
            "border": "1px solid #008C81",
            "border-radius": "12px",
            "cursor": _vm.isLoading.addToCart ? 'not-allowed' : 'pointer',
            "_hover": {
              backgroundColor: '#e9fffb'
            }
          },
          on: {
            "click": function click($event) {
              return _vm.onClickAddToCart(productService || {});
            }
          }
        }, [_vm.isLoading.addToCart ? _c('c-circular-progress', {
          attrs: {
            "is-indeterminate": "",
            "color": "primary",
            "size": "100%"
          }
        }) : _c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-cart.svg'),
            "width": "100%",
            "min-width": "100%",
            "fill": "#008C81"
          }
        })], 1) : _vm._e(), _c('BaseButton', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              color: 'primary.400',
              fontWeight: ['400', '500'],
              fontSize: ['12px', '18px']
            },
            expression: "{\n                color: 'primary.400',\n                fontWeight: ['400', '500'],\n                fontSize: ['12px', '18px']\n              }"
          }],
          attrs: {
            "size": "small",
            "variant": "contained",
            "w": "100%",
            "border-radius": "1000px",
            "color": "secondary",
            "is-loading": _vm.isLoading.buyNow
          },
          on: {
            "click": function click($event) {
              return _vm.onClickBuyNow({
                productId: (productService === null || productService === void 0 ? void 0 : productService.productId) || '',
                productServiceId: (productService === null || productService === void 0 ? void 0 : productService.id) || ''
              });
            }
          }
        }, [_vm._v(" Beli Sekarang ")])], 1)];
      }
    }], null, false, 225910018)
  })], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "margin-top": ['16px', '0'],
      "justify-content": "center",
      "padding": ['0 16px', '0']
    }
  }, [_c('c-flex', {
    attrs: {
      "position": "relative",
      "gap": "12px",
      "padding": ['12px 40px', '12px'],
      "justify-content": "center",
      "align-items": "center",
      "flex-direction": "column",
      "width": "100%",
      "max-width": "850px",
      "background-color": "#FDF9BC",
      "border-top-left-radius": "100px",
      "border-bottom-right-radius": "100px",
      "overflow": "hidden"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "position": "absolute",
      "width": "100px",
      "height": "100px",
      "background-color": "#FAF59A",
      "border-radius": "50%",
      "top": "-40px",
      "left": "0"
    }
  }), _c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "position": "absolute",
      "width": "100px",
      "height": "100px",
      "background-color": "#FAF59A",
      "border-radius": "50%",
      "bottom": "-40px",
      "right": "0"
    }
  }), _c('BaseText', {
    attrs: {
      "position": "relative",
      "size-mobile": "12px-2",
      "size-desktop": "20px",
      "color": "#005A64",
      "text-align": "center"
    }
  }, [_vm._v(" Masih bingung pilih simpel atau intensif program? ")]), _c('BaseButton', {
    attrs: {
      "position": "relative",
      "size": "small",
      "border-radius": "1000px"
    },
    on: {
      "click": function click($event) {
        return _vm.$scrollTo('#table-comparation-target', 500, {
          offset: -80
        });
      }
    }
  }, [_vm._v(" Bandingkan Program ")])], 1)], 1), _c('c-box', {
    attrs: {
      "padding-top": "16px",
      "padding-bottom": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px",
      "margin-bottom": "8px",
      "padding-inline": "16px"
    }
  }, [_vm._v(" Apa Kata Mereka? ")]), _c('c-box', {
    attrs: {
      "padding-inline": ['0px', '16px']
    }
  }, [_vm.listTestimoni.length > 0 ? _c('VueSlickCarousel', _vm._b({
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.slick-slide': {
          padding: '0 16px 8px 0',
          '@media(max-width: 767px)': {
            padding: '0 16px'
          }
        },
        '.slick-dots': {
          bottom: '-25px',
          li: {
            '@media(max-width: 767px)': {
              margin: '0 3px'
            },
            button: {
              '::before': {
                fontSize: '8px',
                color: '#D9D9D9',
                opacity: '1',
                '@media(max-width: 767px)': {
                  fontSize: '10px'
                }
              }
            },
            '&.slick-active': {
              button: {
                '::before': {
                  fontSize: '12px',
                  color: '#008C81',
                  opacity: '1'
                }
              }
            }
          }
        }
      },
      expression: "{\n              '.slick-slide': {\n                padding: '0 16px 8px 0',\n                '@media(max-width: 767px)': {\n                  padding: '0 16px',\n                },\n              },\n              '.slick-dots': {\n                bottom: '-25px',\n                li: {\n                  '@media(max-width: 767px)': {\n                    margin: '0 3px',\n                  },\n                  button: {\n                    '::before': {\n                      fontSize: '8px',\n                      color: '#D9D9D9',\n                      opacity: '1',\n                      '@media(max-width: 767px)': {\n                        fontSize: '10px',\n                      },\n                    },\n                  },\n                  '&.slick-active': {\n                    button: {\n                      '::before': {\n                        fontSize: '12px',\n                        color: '#008C81',\n                        opacity: '1',\n                      },\n                    },\n                  },\n                },\n              },\n            }"
    }]
  }, 'VueSlickCarousel', _vm.settings, false), _vm._l(_vm.listTestimoni, function (item) {
    return _c('c-box', {
      key: item.id
    }, [_c('c-box', {
      attrs: {
        "position": "relative",
        "width": ['100%', '332px'],
        "h": ['auto', '305px'],
        "background-color": "#008C81",
        "border-radius": "12px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "padding-bottom": "52px",
        "margin-bottom": "39px"
      }
    }, [_c('c-box', {
      attrs: {
        "display": "flex",
        "gap": "16px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-width": "60px",
        "width": "60px",
        "height": "60px"
      }
    }, [item.image ? _c('c-image', {
      attrs: {
        "src": item.image,
        "height": "100%",
        "width": "100%",
        "border-radius": "100%",
        "object-fit": "cover",
        "object-position": "50% 20%"
      }
    }) : _vm._e()], 1), _c('c-box', {
      attrs: {
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "width": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "16px",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.age) + " Tahun) ")]), _c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.location) + " ")])], 1)], 1), _c('c-box', {
      attrs: {
        "height": "100%"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "12px",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "justify",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1), _c('c-box', {
      attrs: {
        "width": ['190px', '222px'],
        "min-height": ['64px', '67px'],
        "background-color": "white",
        "position": "absolute",
        "bottom": "0",
        "left": "50%",
        "border": "1px solid #008C81",
        "border-radius": "12px",
        "transform": "translate(-50%, 50%)",
        "padding": "5px",
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "gap": "5px"
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "10px",
        "size-desktop": "12px"
      }
    }, [_vm._v(" Program yang digunakan: " + _vm._s(item.product.name) + " ")]), _c('BaseButton', {
      attrs: {
        "border-radius": "1000px",
        "right-svg-icon": require('@/assets/icons/icon-arrow-right.svg'),
        "right-svg-icon-color": "white",
        "height": "34px"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickTestimoniItem(item);
        }
      }
    }, [_vm._v(" Lihat layanan ")])], 1)], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": ['16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "background-color": "#F1E456",
      "width": "100%",
      "height": "100%",
      "border-radius": ['4px 4px 4px 40px', '4px 4px 40px 100px'],
      "overflow": "hidden",
      "display": "flex"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "absolute",
      "display": ['none', 'block'],
      "background-color": "#F4CC46",
      "width": "500px",
      "height": "500px",
      "border-radius": "100%",
      "z-index": "0",
      "bottom": "15%",
      "left": "5%"
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "min-height": ['113px', '300px'],
      "width": "100%",
      "height": "100%",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "padding": ['12px 10%', 'auto 15%']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/program_page/forenterprise.webp?updatedAt=1685497643580",
      "height": "100%",
      "max-height": ['88.47px', '184.31px']
    }
  })], 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "flex-end",
      "gap": ['5px', '16px'],
      "width": "100%"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "28px",
      "color": "#008C81"
    }
  }, [_vm._v(" For Enterprise ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "12px",
      "size-desktop": "16px",
      "color": "#008C81",
      "text-align": "end"
    }
  }, [_vm._v(" Program Khusus Perusahaanmu ")]), _c('BaseButton', {
    attrs: {
      "size": "medium",
      "border-radius": "1000px",
      "width": ['unset', '290px'],
      "right-svg-icon": require('@/assets/icons/icon-arrow-right.svg'),
      "right-svg-icon-color": "white"
    },
    on: {
      "click": _vm.handleMoreForEnterprise
    }
  }, [_vm._v(" Lebih Lanjut ")])], 1)], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "padding": ['16px 0 0 0', '16px 16px 0 16px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Masalah Lainnya ")]), _c('c-box', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll.x",
      value: !_vm.$isMobile(),
      expression: "!$isMobile()",
      modifiers: {
        "x": true
      }
    }],
    staticClass: "no-scrollbar",
    attrs: {
      "width": "100%",
      "overflow-y": "hidden",
      "overflow-x": "scroll",
      "display": "flex",
      "padding-top": "8px",
      "padding-bottom": "16px"
    },
    on: {
      "!click": function click($event) {
        return function (e) {
          return _vm.dragScrollClickFix.onClickCapture(e);
        }.apply(null, arguments);
      },
      "dragscrollstart": function dragscrollstart($event) {
        return _vm.dragScrollClickFix.onDragScrollStart();
      },
      "dragscrollend": function dragscrollend($event) {
        return _vm.dragScrollClickFix.onDragScrollEnd();
      }
    }
  }, [_c('c-box', {
    attrs: {
      "display": "grid",
      "grid-gap": ['20px', '16px'],
      "grid-template-columns": ['repeat(5, 180px)', 'repeat(5, 230px)'],
      "margin": "auto"
    }
  }, _vm._l(_vm.programDetail.otherProblems, function (item) {
    return _c('c-box', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&:first-child': {
            marginLeft: '16px'
          },
          '&:last-child': {
            marginRight: '16px'
          },
          ':hover': {
            backgroundColor: '#f3f3f3'
          },
          ':active': {
            backgroundColor: '#eaeaea'
          }
        },
        expression: "{\n                '&:first-child': {\n                  marginLeft: '16px'\n                },\n                '&:last-child': {\n                  marginRight: '16px'\n                },\n                ':hover': {\n                  backgroundColor: '#f3f3f3'\n                },\n                ':active': {\n                  backgroundColor: '#eaeaea'\n                }\n              }"
      }],
      key: item.id,
      attrs: {
        "as": "button",
        "background-color": "white",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)'],
        "border-radius": "16px",
        "padding": "16px",
        "display": "flex",
        "flex-direction": "column",
        "gap": "8px",
        "justify-content": "center",
        "align-items": "center"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickOtherProblem(item);
        }
      }
    }, [_c('BaseText', {
      attrs: {
        "size-mobile": "18px",
        "size-desktop": "28px",
        "color": ['#008C81'],
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('c-image', {
      attrs: {
        "src": item.image,
        "min-height": "80px",
        "height": "100%",
        "max-height": ['80px', '100px']
      }
    }), _c('BaseText', {
      attrs: {
        "size-mobile": "14px-2",
        "size-desktop": "14px-2",
        "margin-bottom": "auto",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.description) + " ")])], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }