export const LIST_PROGRAMS = [
  {
    id: 'program-id-clinicare',
    name: 'Clinicare',
    image: 'https://placehold.co/1280',
    description: 'Program clinicare adalah program manajemen pola makan untuk mengelola dan mengontrol penyakit atau kondisi medis. Cocok untuk individu yang mempunyai penyakit seperti GERD, diabetes, kolesterol, dan kondisi medis lainnya.',
    keyPoints: [
      {
        id: 'program-id-personalized',
        name: 'Personalized',
        image: 'https://ik.imagekit.io/dietela/pwa_webp/program_page/sehat_personalized.webp?updatedAt=1685497643701',
        description: 'Program diet didesain khusus sesuai dengan kondisi, kebutuhan, dan goal sehatmu',
      }, {
        id: 'program-id-fleksibel',
        name: 'Fleksibel',
        image: 'https://ik.imagekit.io/dietela/pwa_webp/program_page/sehat_flexible.webp?updatedAt=1685497643655',
        description: 'Boleh masak, pesan makanan, atau jajan. Bebas! Tanpa produk tambahan',
      }, {
        id: 'program-id-dukungan-ahli',
        name: 'Dukungan Ahli',
        image: 'https://ik.imagekit.io/dietela/pwa_webp/program_page/sehat_dukunganahli.webp?updatedAt=1685497643683',
        description: 'Konsultasi dimana saja, kapan saja dengan Ahli Gizi pribadi yang teregistrasi dan berpengalaman',
      }, {
        id: 'program-id-aman',
        name: 'Aman',
        image: 'https://ik.imagekit.io/dietela/pwa_webp/program_page/sehat_aman.webp?updatedAt=1685497643647',
        description: 'Dietela hanya menggunakan metode diet yang aman, terbukti ilmiah dan terbukti hasilnya',
      },
    ],
    otherPrograms: [
      {
        id: 'program-id-body-goals',
        title: 'Berat badan',
        image: 'https://placehold.co/1280',
        description: 'Program diet untuk menurunkan/menaikan berat badan dengan sehat.',
      }, {
        id: 'program-id-clinicare',
        title: 'Penyakit',
        image: 'https://placehold.co/1280',
        description: 'Manajemen pola makan untuk penyakit atau kondisi medis lainnya.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Kesuburan',
        image: 'https://placehold.co/1280',
        description: 'Pemenuhan kebutuhan gizi untuk kesuburan dengan pola diet yang sehat.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Bumil',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi ibu hamil.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Busui',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi busui dan memperlancar produksi ASI.',
      },
    ],
  }, {
    id: 'program-id-body-for-baby',
    name: 'Body for Baby',
    image: 'https://placehold.co/1280',
    description: 'Program body for baby adalah program manajemen pola makan untuk masalah gizi maternal. Cocok untuk calon ibu yang ingin mempersiapkan kehamilan, manajemen pola makan saat hamil atau menyusui.',
    keyPoints: [
      {
        id: 'program-id-personalized',
        name: 'Personalized',
        image: 'https://placehold.co/1280',
        description: 'Program diet didesain khusus sesuai dengan kondisi, kebutuhan, dan goal sehatmu',
      }, {
        id: 'program-id-fleksibel',
        name: 'Fleksibel',
        image: 'https://placehold.co/1280',
        description: 'Boleh masak, pesan makanan, atau jajan. Bebas! Tanpa produk tambahan',
      }, {
        id: 'program-id-dukungan-ahli',
        name: 'Dukungan Ahli',
        image: 'https://placehold.co/1280',
        description: 'Konsultasi dimana saja, kapan saja dengan Ahli Gizi pribadi yang teregistrasi dan berpengalaman',
      }, {
        id: 'program-id-aman',
        name: 'Aman',
        image: 'https://placehold.co/1280',
        description: 'Dietela hanya menggunakan metode diet yang aman, terbukti ilmiah dan terbukti hasilnya',
      },
    ],
    otherPrograms: [
      {
        id: 'program-id-body-goals',
        title: 'Berat badan',
        image: 'https://placehold.co/1280',
        description: 'Program diet untuk menurunkan/menaikan berat badan dengan sehat.',
      }, {
        id: 'program-id-clinicare',
        title: 'Penyakit',
        image: 'https://placehold.co/1280',
        description: 'Manajemen pola makan untuk penyakit atau kondisi medis lainnya.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Kesuburan',
        image: 'https://placehold.co/1280',
        description: 'Pemenuhan kebutuhan gizi untuk kesuburan dengan pola diet yang sehat.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Bumil',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi ibu hamil.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Busui',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi busui dan memperlancar produksi ASI.',
      },
    ],
  }, {
    id: 'program-id-body-goals',
    name: 'Body Goals',
    image: 'https://placehold.co/1280',
    description: 'Body goals adalah program diet untuk masalah yang berhubungan dengan berat badan. Cocok untuk individu yang ingin menurunkan atau menaikan berat badannya dengan cara yang sehat tanpa tersiksa.',
    keyPoints: [
      {
        id: 'program-id-personalized',
        name: 'Personalized',
        image: 'https://placehold.co/1280',
        description: 'Program diet didesain khusus sesuai dengan kondisi, kebutuhan, dan goal sehatmu',
      }, {
        id: 'program-id-fleksibel',
        name: 'Fleksibel',
        image: 'https://placehold.co/1280',
        description: 'Boleh masak, pesan makanan, atau jajan. Bebas! Tanpa produk tambahan',
      }, {
        id: 'program-id-dukungan-ahli',
        name: 'Dukungan Ahli',
        image: 'https://placehold.co/1280',
        description: 'Konsultasi dimana saja, kapan saja dengan Ahli Gizi pribadi yang teregistrasi dan berpengalaman',
      }, {
        id: 'program-id-aman',
        name: 'Aman',
        image: 'https://placehold.co/1280',
        description: 'Dietela hanya menggunakan metode diet yang aman, terbukti ilmiah dan terbukti hasilnya',
      },
    ],
    otherPrograms: [
      {
        id: 'program-id-clinicare',
        title: 'Penyakit',
        image: 'https://placehold.co/1280',
        description: 'Manajemen pola makan untuk penyakit atau kondisi medis lainnya.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Kesuburan',
        image: 'https://placehold.co/1280',
        description: 'Pemenuhan kebutuhan gizi untuk kesuburan dengan pola diet yang sehat.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Bumil',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi ibu hamil.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Busui',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi busui dan memperlancar produksi ASI.',
      }, {
        id: 'program-id-body-goals',
        title: 'Berat badan',
        image: 'https://placehold.co/1280',
        description: 'Program diet untuk menurunkan/menaikan berat badan dengan sehat.',
      },
    ],
  }, {
    id: 'program-id-for-enterprise',
    name: 'For Enterprise',
    image: 'https://placehold.co/1280',
    description: 'Mulai hidup sehat di kantormu, bersama Dietela.',
    keyPoints: [
      {
        id: 'program-id-personalized',
        name: 'Personalized',
        image: 'https://placehold.co/1280',
        description: 'Program diet didesain khusus sesuai dengan kondisi, kebutuhan, dan goal sehatmu',
      }, {
        id: 'program-id-fleksibel',
        name: 'Fleksibel',
        image: 'https://placehold.co/1280',
        description: 'Boleh masak, pesan makanan, atau jajan. Bebas! Tanpa produk tambahan',
      }, {
        id: 'program-id-dukungan-ahli',
        name: 'Dukungan Ahli',
        image: 'https://placehold.co/1280',
        description: 'Konsultasi dimana saja, kapan saja dengan Ahli Gizi pribadi yang teregistrasi dan berpengalaman',
      }, {
        id: 'program-id-aman',
        name: 'Aman',
        image: 'https://placehold.co/1280',
        description: 'Dietela hanya menggunakan metode diet yang aman, terbukti ilmiah dan terbukti hasilnya',
      },
    ],
    otherPrograms: [
      {
        id: 'program-id-clinicare',
        title: 'Penyakit',
        image: 'https://placehold.co/1280',
        description: 'Manajemen pola makan untuk penyakit atau kondisi medis lainnya.',
      }, {
        id: 'program-id-body-goals',
        title: 'Berat badan',
        image: 'https://placehold.co/1280',
        description: 'Program diet untuk menurunkan/menaikan berat badan dengan sehat.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Bumil',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi ibu hamil.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Busui',
        image: 'https://placehold.co/1280',
        description: 'Manajemen diet untuk pemenuhan gizi busui dan memperlancar produksi ASI.',
      }, {
        id: 'program-id-body-for-baby',
        title: 'Kesuburan',
        image: 'https://placehold.co/1280',
        description: 'Pemenuhan kebutuhan gizi untuk kesuburan dengan pola diet yang sehat.',
      },
    ],
  },
]